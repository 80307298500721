import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";
import Avatar from "shared/Avatar/Avatar";

export interface CardCategory5Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategory5: FC<CardCategory5Props> = ({
  className = "",
  taxonomy,
}) => {
  const { clientName, img, username, content, rating } = taxonomy;
  const filledStars = Math.round(rating);
  return (
    <div
      className={`bg-white rounded-2xl h-64 w-[320px] p-4 m-4${className}`}
      style={{ boxShadow: "0px 0px 15px 5px rgba(193, 193, 193, 0.25)" }}
    >
      <div className=" gap-4 flex flex-row">
        <div className="col-1">
          <Avatar imgUrl={img} sizeClass="w-8 h-8 sm:w-9 sm:h-9" />
        </div>
        <div className="col-2">
          <h2
            className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate flex-col`}
          >
            {clientName}
          </h2>
          {/* <div className="flex justify-center gap-4">
            <p>{username}</p>
          </div> */}
          <div className="flex">
            {[...Array(5)].map((_, index) => {
              const starColor =
                index < filledStars ? "text-yellow-500" : "text-gray-300";
              return (
                <span key={index} className={`text-2xl ${starColor}`}>
                  &#9733;
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <div className=" mt-2 overflow-hidden">
        <p className="line-clamp-6 text-left">{content}</p>
      </div>
    </div>
  );
};

export default CardCategory5;
