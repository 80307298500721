import rightImg from "images/image.jpg";
import React, { FC, useEffect } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import Requirement from "./Requirement";
import SectionContact from "containers/PageContact/SectionContact";
import { useLocation } from "react-router-dom";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Car rental</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      {/* <BgGlassmorphism /> */}

      <div className="container py-8 lg:py-14 space-y-8 lg:space-y-14">
        <SectionHero
          rightImg={rightImg}
          heading="About Us"
          btnText=""
          subHeading={globalJson?.about_us_para}
        />
        <div id="section">
          <Requirement />
        </div>
        {/* <SectionFounder /> */}

        {/* <SectionStatistic /> */}

        {/* <SectionSubscribe2 /> */}
      </div>
      <div className="relative py-16 mb-20 lg:mb-36 mx-4">
        {/* <BackgroundSection /> */}
        <SectionSliderNewCategories
          categoryCardType="card4"
          itemPerRow={4}
          heading="Testimonials"
          subHeading="Popular places to stay that Chisfis recommends for you"
          sliderStyle="style2"
          uniqueClassName="PageHome_s2"
        />
      </div>
      <div className="container relative" style={{ padding: "0px" }}>
        <SectionContact />
      </div>
    </div>
  );
};

export default PageAbout;
