import Heading from "components/Heading/Heading";
import React from "react";

function Requirement() {
  return (
    <div>
      <p className="text-5xl font-semibold leading-16 text-left text-text-color mb-4">
        Requirements
      </p>
      <p className="text-base leading-6  text-justify text-white-6000">
        1. Reservation Policy: Reservations are subject to availability and must
        be made in advance to ensure vehicle availability. Once a reservation is
        made, 100% payment is due to hold the date(s)/car(s).
        <br /> 2. Driver Requirements: All drivers must be 21 years and older
        with a valid driver’s license and clean driving records (no major moving
        violations, DUI convictions, or similar infractions). Additional fees
        may apply for drivers under 25 years old.
        <br /> 3. Insurance Coverage: Customers must provide proof of
        full-coverage insurance (liability, comprehensive, collision, and loss
        of use). If you do not have auto insurance, it must be purchased at the
        time of booking. Each driver is required to provide their own car
        insurance that covers any liability claim up to $100k/$300k/$50k and has
        full comprehensive and collision coverage. The declaration page of their
        insurance coverage must be sent to us by email, and we will verify with
        the insurance company if that coverage will transfer over to the vehicle
        being rented. If you have basic liability insurance, collision insurance
        can be purchased. Security deposits will be returned within seven days
        of the trip ending, and any toll charges or tickets will be deducted
        from the security deposit.
        <br /> 4. Payment: Payment is due at the time of reservation or vehicle
        pickup. Accepted payment methods include credit/debit cards and cash
        deposits.
        <br /> 5. Cancellation Policy: There is no penalty for cancellation or
        rescheduling up to 72 hours before the rental date. Cancellations made
        less than 48 hours prior to the rental will incur a penalty of 50% of
        the total rental cost. If you cancel within 24 hours of your rental or
        do not show up at the time of your rental, you will be charged a
        cancellation fee of 100% of the total rental cost.
        <br /> 6. Vehicle Return: Vehicles must be returned at the agreed-upon
        time and location. Late returns may result in additional charges. <br />
        7. Fuel Policy: Vehicles must be returned with the same fuel level as at
        the time of pickup.
        <br /> 8. Rental Extensions: Rental extensions depend on availability
        and approval by us.
        <br /> 9. Vehicle Assurance: If you decide to book a certain type of
        car, you will get the exact same one as shown on our website.
      </p>
    </div>
  );
}

export default Requirement;
