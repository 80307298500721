import clientSay1 from "images/clientSay1.png";
import clientSay2 from "images/clientSay2.png";
import clientSay3 from "images/clientSay3.png";
import clientSay4 from "images/clientSay4.png";
import clientSay6 from "images/clientSay6.png";

export const globalJson = {
  // apiUtil auth header key
  authToken: "Bearer 158|h4DYrQR3VXujAHlaC6Pj4mQjsyv9IJtYbTRzn74t",
  companyId: "ed83bf77-6dfb-415c-be86-af8961fac56b",
  stripePublishableKey:
    "pk_live_51PHOv6KfY7l1j8If0ik82CCT26aV8Se3n7Aood6YYyVNgzVmAjWgrAgl6GbWGtDZWWqrfCf1mdG3MekmH2aEWrVL00MBIXWw99",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "NY",

  //email confirmation admin token
  adminEmailToken:
    "c8Jvoq8pucjGUIUN4LQuTCX8oubsbUiPqHTXuqdVUlXSiROr73YtcBLlte5vNoInyrhYBa00qBybWzW3JQwrQIKHP6KtQRgQlNrUwdpwKMqAuKegFE58wa69OJR27QDk",
  websiteLink: "https://www.107carrentals.com",
  sender: "107moto",
  ccEmail: "107motors@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_00d14ge",
  util_templateId: "template_tb6mq29",
  util_userId: "mOCzJxdTaBRdarihR",
  util_privateKey: "Csjl1ZeICa4HRQOb6KGOm",

  // apiUtil getBonzahToken
  client_secret_dev:
    "a/kqxs/4A8yI3Yo9Z0MiaqHdhI3XGGUNGBr8h0CPZkulkS1rzwOYtFnYXFvK/yKI3Qi4TwMtosnAwOttDsvxVXhWh4Z+FrJGpw6h3rxQqKMXcW2yZ2E5hxv0Jmv6UppmfawCgQ==",
  client_id_dev:
    "8eaa0abc-64fe-49c4-a909-803c9c96426e-20240529259577-vendor@bonzah.com",
  client_secret_prod:
    "cb5QrMBvFtfzr0gGrcadAH9t+2qfmAVWe9qbFOB+VNwfocu5DQ0NccYfNceFd+E9WjNe+nmhDZMq3+dBsXIG3xVlim18SYWlcIgFCjuqN4XYzzXw83x2ImQcBTybXXyijwjHJQ==",
  client_id_prod:
    "40a8afae-e999-4d1a-8453-fa1a59cbe77c-20240529297717-vendor@bonzah.com",

  agreement_key:
    "https://firebasestorage.googleapis.com/v0/b/moto-75f9e.appspot.com/o/agreements%2F",
  bookingId_key: ".pdf?alt=media&token=44d98d82-4d42-4595-b44b-bc44aa73d68e",

  //   bg_config
  firebaseConfig: {
    apiKey: "AIzaSyD0fOkiODzi-0icimBckFhjY2d-spAnEag",
    authDomain: "moto-75f9e.firebaseapp.com",
    projectId: "moto-75f9e",
    storageBucket: "moto-75f9e.appspot.com",
    messagingSenderId: "175306864410",
    appId: "1:175306864410:web:c9764be902ed898a77dea3",
    measurementId: "G-JPDZY72S8M",
  },

  about_us_para:
    "We believe car rentals shouldn't be a hassle. At 107moto, we go the extra mile to ensure a stress-free experience, from convenient online booking to friendly and knowledgeable staff ready to assist you. Your satisfaction is our top priority.",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Ben Arnoux",

      content:
        "We rented a car from this company and had a very pleasant time. Customer service was excellent and the entire experience was overall 10/10. I would highly recommend this professional team to anyone. Communication was excellent and they were happy to help with any concerns. Car ran very smooth with no issues at all. Very clean with no dust marks anywhere at all, spotless interior.",
      username: "John",
      img: clientSay1,
      rating: 5,
    },
    {
      id: 2,
      clientName: "Jora Bolena",

      content:
        "We were extremely pleased with the service!!! Riaz was very straightforward and courteous. There was no fluff in his approach, which I deeply appreciated. Our vehicle was clean and priced fairly. Would definitely recommend!",
      username: "John",
      img: clientSay2,
      rating: 5,
    },
    {
      id: 3,
      clientName: "Stanley Boyce ",

      content:
        "I don’t know about anyone else but this is the place to be and the person to see Riaz is a great and wonderful host he provides too notch service when I am renting his vehicle his vehicle is clean and comfortable the ride is smooth and you are taken care of pickup is convenient and he has no problem going out if his way to provide quality service thanks for the quality service and cleanliness that you afford your renters APPRECIATE YOUR BUSINESS 10/10",
      username: "John",
      img: clientSay3,
      rating: 4.8,
    },
    {
      id: 4,
      clientName: "Anthony Soluri",

      content:
        "I had a great experience. Riaz helped me with everything. Very professional. I would and will recommend him in a minute.",
      username: "John",
      img: clientSay4,
      rating: 4.6,
    },
    {
      id: 5,
      clientName: "Peter H",
      content:
        "The silver Versa is in great shape and the host is on top of everything.",
      username: "John",
      img: clientSay6,
      rating: 5,
    },
  ],

  //social links
  facebook: "https://www.facebook.com/107Motors",
  instagram: "https://www.instagram.com/",

  //contact
  address: "7 Rave Street, Hicksville, NY 11801",
  addressLink:
    "https://www.google.com/maps/place/7+Rave+Sthttps://www.google.com/maps/place/7+Rave+St,+Hicksville,+NY+11801,+USA/data=!4m2!3m1!1s0x89c280f876b0780d:0x2203df33ec0ac630?sa=X&ved=1t:242&ictx=111",
  phone: "5167039888",
  email: "107carrentals@gmail.com",
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46476469.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
