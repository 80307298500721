import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/travelhero2.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="hidden lg:grid absolute z-10 inset-x-0 flex-col items-center grid-cols-2 xl:gap-36 lg:gap-16 text-left ">
        <div className="xl:ml-16 ml-12">
          <h2 className="font-bold text-text-color  sm:text-4xl md:text-5xl lg:text-5xl xl:text-7xl inline-block bg-opacity-50 bg-white px-4 py-2 rounded-lg">
            107 Car Rentals
          </h2>

          <h2 className="font-bold text-5xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-7xl text-white">
            Won’t Let You
            <br /> Down
          </h2>
        </div>

        <div className="hidden lg:block lg:w-[400px] xl:w-[509px] ">
          <HeroSearchForm />
        </div>
        {/* <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
        >
          Keep calm & travel on
        </ButtonPrimary> */}
      </div>
      <div className="block lg:hidden absolute z-10 inset-x-0  top-1/4 sm:-translate-y-1/2 md:top-1/3 md:-translate-y-1/2  lg:translate-y-0 text-center flex-col items-center mx-auto">
        {" "}
        <h2 className="font-bold text-text-color text-5xl  inline-block bg-opacity-50 bg-white px-4 py-2 rounded-lg ">
          107 Car Rentals
        </h2>
        <h2 className="font-bold text-5xl text-white ">
          Won’t Let You
          <br /> Down
        </h2>
      </div>
      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
        <img
          className="absolute inset-0 object-cover "
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default SectionHero3;
