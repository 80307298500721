import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { CarsListing } from "models/CarsListingModel";
import { useLocation } from "react-router-dom";

export interface FleetCardProps {
  className?: string;
  data?: CarsListing;
  size?: "default" | "small";
  simpleImg?: boolean;
  onClick?: any;
}

const FleetCard: FC<FleetCardProps> = ({
  size = "default",
  className = "",
  data,
  simpleImg = false,
  onClick,
}) => {
  const { listing_images, name, l_id, pricing, vehicle } = data!;
  const location = useLocation();
  const isAvailableCarPath = location.pathname === "/available-car";

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <img
          src={
            listing_images.length > 0
              ? listing_images[0].path
              : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
          }
          alt="name"
          className="h-52 object-cover w-full"
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize text-text-color ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1 font-bold">{name}</span>
            </h2>
          </div>

          <span className=" text-sm" style={{color:"#9AA3AD"}}>{vehicle?.seats} seats {vehicle?.color} color</span>
        </div>
        {/* <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div> */}
        <div className="flex justify-between items-center">
          <span className="text-base font-bold text-text-color">
            {"$" + pricing.amount / 100}
            {` `}
            {size === "default" && (
              <span className="text-sm font-normal" style={{color:"#9AA3AD"}}>
                /day
              </span>
            )}
          </span>
          {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${
        isAvailableCarPath ? "cursor-pointer" : ""
      } nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
      onClick={onClick}
  
    >
      {renderSliderGallery()}
      {/* <Link to={"/"}> */}
      {renderContent()}
      {/* </Link> */}
    </div>
  );
};

export default FleetCard;
