import { FC, useEffect, useState } from "react";
import { CarDataType } from "data/types";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { useApp } from "data/app-provider";
import FleetCard from "components/FleetCard/FleetCard";
import CarsListingModel, { CarsListing } from "models/CarsListingModel";
import { useNavigate } from "react-router-dom";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
}) => {
  const navigate = useNavigate();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const {
    availableFleet,
    setSelectedCar,
    setSelectedExtras,
    setSelectedBonzahProduct,
    setSelectedInsuranceProtection,
    startDate,
    endDate,
    searchedPickupLoction,
    pickupLocation,
  } = useApp();
  let maxPrice = availableFleet
    ? availableFleet.listings.reduce(
        (acc, current) => Math.max(acc, current.pricing.amount / 100),
        0
      )
    : 0;
  const [filteredFleet, setFilteredFleet] = useState<CarsListingModel | null>(
    availableFleet
  );
  const [rangePrices, setRangePrices] = useState([0, maxPrice]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!pickupLocation) {
      navigate("/");
    }
  }, [pickupLocation]);

  useEffect(() => {
    if (
      selectedTypes.length == 0 ||
      Array.from(
        new Set(
          availableFleet!.listings.map(
            (car) => car?.vehicle?.vehicle_class?.name
          )
        )
      ).filter((item): item is string => item !== undefined).length ==
        selectedTypes.length
    ) {
      setFilteredFleet(availableFleet);
    } else {
      setFilteredFleet({
        success: true,
        listings: availableFleet!.listings.filter((item: CarsListing) =>
          selectedTypes.includes(item?.vehicle?.vehicle_class?.name ?? "")
        ),
      });
    }
  }, [selectedTypes, availableFleet]);

  useEffect(() => {
    setFilteredFleet({
      success: true,
      listings: availableFleet!.listings.filter(
        (item: CarsListing) =>
          item.pricing.amount / 100 >= rangePrices[0] &&
          item.pricing.amount / 100 <= rangePrices[1]
      ),
    });
  }, [rangePrices]);

  const renderCard = (car: CarsListing) => {
    return (
      <FleetCard
        key={car.l_id}
        data={car}
        simpleImg={true}
        onClick={() => {
          {
            setSelectedCar(car);
            setSelectedExtras(new Map());
            setSelectedBonzahProduct([]);
            setSelectedInsuranceProtection([]);
            navigate("/listing-car-detail");
          }
          return;
        }}
      />
    );
  };
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      style={{ minHeight: "80vh" }}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="flex flex-col sm:flex-row justify-between">
        <Heading2
          heading={"Available Fleet (" + availableFleet?.listings.length + ")"}
          subHeading=" "
        />

        <div className="mb-8 lg:mb-11">
          <TabFilters
            types={[
              ...Array.from(
                new Set(
                  availableFleet!.listings.map(
                    (car) => car?.vehicle?.vehicle_class?.name
                  )
                )
              ).filter((item): item is string => item !== undefined),
            ]}
            selectedTypes={selectedTypes}
            setSelectedTypes={setSelectedTypes}
            rangePrices={rangePrices}
            setRangePrices={setRangePrices}
            maxPrice={maxPrice}
          />
        </div>
      </div>
      <div className="nc-SectionGridFeaturePlaces relative">
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
        >
          {filteredFleet?.listings.map((car) => renderCard(car))}
        </div>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
