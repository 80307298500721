"use client";

import { FC } from "react";
import LocationInput from "../LocationInput";
import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";
import RentalCarTimesRangeInput from "./RentalCarTimesRangeInput";
import { useApp } from "data/app-provider";
import DropOffLocationInput from "../DropOffLocationInput";

export interface RentalCarSearchFormProps {}

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = () => {
  const {
    dropOffLocationType,
    setDropOffLocationType,
    setDropOffLocation,
    pickupLocation,
    dropOffLocationExtras,
    setSelectedDropOffLocationExtra,
    setSearchedDropoffLocation,
  } = useApp();

  const renderRadioBtn = () => {
    return (
      <div className="py-3 flex items-center flex-wrap flex-row border-b border-neutral-100 dark:border-neutral-700">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div
            className={`p-4 flex items-center justify-center rounded-xl cursor-pointer text-base font-semibold leading-6 tracking-tighter text-left ${
              dropOffLocationType === "same"
                ? "bg-white text-text-color shadow-black/10 opacity-100"
                : " text-text-color"
            }`}
            onClick={(e) => {
              setDropOffLocationType("same");
              setDropOffLocation(pickupLocation);
              setSearchedDropoffLocation(pickupLocation?.name ?? "");
              dropOffLocationExtras?.options.map((location) => {
                if (location.title === pickupLocation?.name) {
                  setSelectedDropOffLocationExtra(location);
                }
              });
            }}
          >
            Same Drop Off
          </div>
          <div
            className={`px-6 py-2 flex items-center justify-center rounded-xl text-base font-semibold leading-6 tracking-tighter text-left cursor-pointer ${
              dropOffLocationType === "different"
                ? "bg-white text-text-color  shadow-black/10 "
                : " text-text-color"
            }`}
            onClick={(e) => setDropOffLocationType("different")}
          >
            Different Drop Off
          </div>
        </div>
      </div>
    );
  };

  const isDdropOffdifferent = dropOffLocationType === "different";

  return (
    <form className="w-full relative py-1 px-4  mt-8 rounded-3xl shadow-xl dark:shadow-2xl bg-white bg-opacity-80">
      {renderRadioBtn()}
      <div
        className={`relative flex flex-col ${
          isDdropOffdifferent ? "md:grid md:grid-cols-2 md:gap-4" : ""
        }`}
      >
        <div className={`${isDdropOffdifferent ? "md:col-span-1" : "w-full"}`}>
          <LocationInput
            placeHolder="Pick Up Location"
            className="w-full bg-white rounded-xl"
            sameDropOffLocation={dropOffLocationType === "same"}
          />
        </div>
        {isDdropOffdifferent && (
          <div className="md:col-span-1">
            <DropOffLocationInput
              placeHolder="Dropoff Location"
              className="w-full bg-white rounded-xl"
              divHideVerticalLineClass="-inset-x-0.5"
            />
          </div>
        )}
      </div>
      <div className="relative flex flex-col">
        <RentalCarDatesRangeInput className="w-full bg-white rounded-xl my-2" />

        <RentalCarTimesRangeInput
          dropOffLocationType={dropOffLocationType}
          className="w-full bg-white rounded-xl"
        />
      </div>
    </form>
  );
};

export default RentalCarSearchForm;
