import rightImg from "images/image.jpg";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionFounder from "containers/PageAbout/SectionFounder";
import DownloadLink from "./RentalAgreement";
import { globalJson } from "global/global_json";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import Heading from "components/Heading/Heading";
import PageContact from "containers/PageContact/PageContact";
import SectionContact from "containers/PageContact/SectionContact";

function PageHome3() {
  return (
    <div className="nc-PageHome3 relative overflow-hidden ">
      {/* GLASSMOPHIN */}
      {/* <BgGlassmorphism /> */}
      {/* <DownloadLink /> */}

      {/* SECTION HERO */}
      <SectionHero3 />
      {/* <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 "></div> */}
      <div className="container relative py-8">
        <SectionGridFeaturePlaces />
      </div>

      <div className="container relative" style={{ padding: "0px" }}>
        {/* ======== BG GLASS ======== */}
        {/* <BgGlassmorphism /> */}

        <div className="container py-8 lg:py-14 space-y-8 lg:space-y-14">
          <SectionHero
            rightImg={rightImg}
            heading="About Us"
            btnText=""
            subHeading={globalJson?.about_us_para}
          />
          {/* <div>
            <Heading>Our Team</Heading>
            <div className="container overflow-hidden">
             
              <div className="relative py-16">
                <BackgroundSection />
                <SectionSliderNewCategories
                  heading="Our Team"
                  categoryCardType="card5"
                  itemPerRow={5}
                  sliderStyle="style2"
                  uniqueClassName="ListingRealEstateMapPage"
                />
              </div>
            </div>
          </div> */}
          {/* <SectionFounder/> */}
          <div>
            {/* <Heading>Our clients say</Heading> */}

            {/* <SectionSubscribe2 /> */}
          </div>
        </div>
      </div>
      <div className="relative py-16 mb-20 lg:mb-36">
        {/* <BackgroundSection /> */}
        <SectionSliderNewCategories
          categoryCardType="card4"
          itemPerRow={4}
          heading="Testimonials"
          subHeading="Popular places to stay that Chisfis recommends for you"
          sliderStyle="style2"
          uniqueClassName="PageHome_s2"
        />
        {/* <SectionClientSay uniqueClassName="PageAbout_" /> */}
      </div>
      <div className="container relative" style={{ padding: "0px" }}>
        <SectionContact />
      </div>
    </div>
  );
}

export default PageHome3;
