import React, { FC, useState } from "react";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import toast from "react-hot-toast";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "data/firebase";
import { AuthPages } from "routers";
import { useApp } from "data/app-provider";

export interface PageForgotPassProps {
  className?: string;
  setAuthPageName: React.Dispatch<React.SetStateAction<AuthPages>>;
}

const PageForgotPass: FC<PageForgotPassProps> = ({
  className = "",
  setAuthPageName,
}) => {
  const { setIsAuthPanelOpen } = useApp();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      setLoading(false);
      return;
    }
    await sendPasswordResetEmail(auth, email);
    toast.success("Email sent successfully. Check inbox");
    setEmail("");
    setLoading(false);
    setIsAuthPanelOpen(false);
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <div className="popup-container mb-24 " style={{ minHeight: "60vh" }}>
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Forgot Password
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSignIn}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1 rounded-xl"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>

            <ButtonPrimary type="submit" className="rounded-xl">
              {loading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                </div>
              ) : (
                "Send Email"
              )}
            </ButtonPrimary>
          </form>

          <span className="block text-center text-text-color dark:text-text-color">
            New user? {` `}
            <div
              className="font-bold hover:cursor-pointer"
              onClick={() => {
                setAuthPageName(AuthPages.Signup);
              }}
            >
              Create an account
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageForgotPass;
