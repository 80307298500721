import React, { FC, useState } from "react";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { signUpWithEmailAndPassword, signInWithGoogle } from "data/firebase"; // Assuming you have implemented these functions
import { useApp } from "data/app-provider";
import toast from "react-hot-toast";
import { AuthPages } from "routers";

export interface PageSignUpProps {
  className?: string;
  setAuthPageName: React.Dispatch<React.SetStateAction<AuthPages>>;
}

const loginSocials = [
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];
const PageSignUp: FC<PageSignUpProps> = ({
  className = "",
  setAuthPageName,
}) => {
  const navigate = useNavigate();
  const { setUser, fleetwireToken, selectedCar, setIsAuthPanelOpen } = useApp();
  const redirectUrl = sessionStorage.getItem("redirectUrl");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();

    try {
      await signUpWithEmailAndPassword(setUser, email, password, setLoading);
      setIsAuthPanelOpen(false);
    } catch (error) {
      console.error("Sign up error:", error);
    }
  };

  return (
    <div
      className={`nc-Pnavigate: any, setUser: unknownclassName}`}
      data-nc-id="PageSignUp"
    >
      <Helmet>
        <title>Sign up || Car rental</title>
      </Helmet>
      <div className="popup-container mb-24" style={{ minHeight: "60vh" }}>
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-text-color dark:text-text-color justify-center">
          Sign Up
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <div
                key={index}
                onClick={async () => {
                  await signInWithGoogle(setUser);
                  setIsAuthPanelOpen(false);
                }}
                className=" cursor-pointer nc-will-change-transform flex w-full border border-neutral-200 rounded-xl bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-text-color dark:text-text-color sm:text-sm">
                  {item.name}
                </h3>
              </div>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm  dark:text-text-color dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSignUp}>
            <label className="block">
              <span className="text-text-color dark:text-text-color">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1 rounded-xl"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-text-color dark:text-text-color">
                Password
              </span>
              <div className="relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  className="mt-1 w-full pr-10 rounded-xl"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </label>
            <ButtonPrimary type="submit" className="min-h-16 rounded-xl">
              {loading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-gray-300"></div>
                </div>
              ) : (
                "Continue"
              )}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-text-color dark:text-text-color">
            Already have an account? {` `}
            <div
              className="font-bold"
              onClick={() => {
                setAuthPageName(AuthPages.Login);
              }}
            >
              Sign in
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
