import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { sendNewsLetterEmail } from "utils/apiUtil";
import toast from "react-hot-toast";
import { globalJson } from "global/global_json";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "Address",
    desc: globalJson.address,
  },
  {
    title: "Email",
    desc: globalJson.email,
  },
  {
    title: "Phone",
    desc: globalJson.phone,
  },
];

const SectionContact: FC<PageContactProps> = ({ className = "" }) => {
  const [getEmail, setEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!getEmail.trim() || !emailRegex.test(getEmail)) {
      toast.error("Please enter a valid email address");
      setLoading(false);
      return;
    }

    if (getEmail.length == 0 || newName.length == 0 || message.length == 0) {
      toast.error("Fields are required!");
      setLoading(false);
      return;
    }
    await sendNewsLetterEmail(getEmail, newName, message);
    setEmail("");
    setNewName("");
    setMessage("");
    toast.success("Send successfully!");
    setLoading(false);
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <div className="mb-24 lg:mb-32">
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-3 gap-12">
            <div className="max-w-sm space-y-8 sm:col-span-1">
              <h2 className="flex justify-start items-center leading-[115%] md:text-4xl md:leading-[115%] text-text-color text-5xl font-semibold leading-72 text-left">
                Contact Us
              </h2>
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="font-bold text-2xl text-gray-600">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-blue-700 text-xl">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="font-bold text-2xl text-gray-600">Socials</h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div className="listingSection__wrap lg:shadow-xl rounded-3xl bg-white p-5 sm:col-span-2">
              <form className="grid grid-cols-1 gap-3" onSubmit={sendEmail}>
                <label className="block">
                  <div className="border py-1 px-4 rounded-xl bg-field-color">
                    <label className=" nc-Label text-sm font-bold text-neutral-700 dark:text-neutral-300 text-text-color">
                      Full Name
                    </label>
                    <Input
                      placeholder="What's Your Full Name?"
                      type="text"
                      className="mt-1 rounded-xl border-none focus:outline-none focus:ring-0"
                      style={{
                        padding: "0px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: "rgba(242, 244, 246)",
                      }}
                      value={newName}
                      onChange={(e) => {
                        setNewName(e.target.value);
                      }}
                    />
                  </div>
                </label>
                <label className="block">
                  <div className="border py-1 px-4 rounded-xl bg-field-color">
                    <label className="nc-Label text-sm font-bold text-neutral-700 dark:text-neutral-300 text-text-color">
                      Email Address
                    </label>
                    <Input
                      type="email"
                      placeholder="What's Your Email Address?"
                      className="mt-1 rounded-xl border-none focus:outline-none focus:ring-0"
                      style={{
                        padding: "0px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: "rgba(242, 244, 246)",
                      }}
                      value={getEmail}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </label>
                <label className="block">
                  <div className="border py-1 px-4 rounded-xl bg-field-color">
                    <label className=" nc-Label text-sm font-bold text-neutral-700 dark:text-neutral-300 text-text-color">
                      Message
                    </label>
                    <Textarea
                      className="mt-1 rounded-xl border-none focus:outline-none focus:ring-0"
                      placeholder="We Would Love To Help You"
                      style={{
                        padding: "1px",
                        backgroundColor: "rgba(242, 244, 246)",
                        fontSize: "12px",
                      }}
                      rows={6}
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </div>
                </label>
                <div>
                  <ButtonPrimary
                    type="submit"
                    className="min-w-full rounded-xl"
                  >
                    {loading ? (
                      <div className="flex justify-center items-center col-span-full">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionContact;
