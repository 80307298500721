import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { useApp } from "data/app-provider";
import { Insurance, StripeVerification } from "models/FleetwireCustomer";
import { BonzahProduct } from "models/BonzahProducts";
import { CarsListing } from "models/CarsListingModel";
import { formatDateTime } from "utils/converSelectedDateToString";
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    flexDirection: "column",
    display: "flex",
    backgroundColor: "rgb(243, 244, 246)",
  },
  heading1: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 22,
    color: "rgba(0, 42, 98, 1)",
  },
  heading2: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 16,
    color: "rgba(0, 42, 98, 1)",
  },
  heading3: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 14,
    color: "rgba(0, 42, 98, 1)",
  },
  text: {
    //    fontFamily: "Arial",
    fontSize: 14,
    color: "rgba(0, 42, 98, 1)",
  },
  textBold: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 14,
    color: "rgba(0, 42, 98, 1)",
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    marginBottom: 10,
    flexDirection: "column",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
  },
  tableCol: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  tableCell: {
    margin: 5,
    fontSize: 14,
    // fontFamily: "Arial",
  },
  tableHeader: {
    fontWeight: "bold",
    backgroundColor: "#d3d3d3",
  },
});

const signatureStylesText = [
  "Playball",
  "Qwitcher Grypen",
  "Montez",
  "My Soul",
  "Ms Madi",
  "Niconne",
];

// // Register fonts with direct URLs
Font.register({
  family: "Playball",
  src: "/fonts/Playball-Regular.ttf",
});
Font.register({
  family: "Qwitcher Grypen",
  src: "/fonts/QwitcherGrypen-Regular.ttf",
});
Font.register({
  family: "Montez",
  src: "/fonts/Montez-Regular.ttf",
});
Font.register({
  family: "My Soul",
  src: "/fonts/MySoul-Regular.ttf",
});
Font.register({
  family: "Ms Madi",
  src: "/fonts/MsMadi-Regular.ttf",
});
Font.register({
  family: "Niconne",
  src: "/fonts/Niconne-Regular.ttf",
});

// Create Document Component

export interface RentalAgreemaentProps {
  selectedSignatureIndex: number;
  stripeVerification: StripeVerification;
  selectedBonzahProduct: BonzahProduct[];
  axleInsurance: Insurance | null;
  bonzahCertificate?: string;
  selectedCar: CarsListing;
  startDate: Date;
  endDate: Date;
}
const RentalAgreement: React.FC<RentalAgreemaentProps> = ({
  selectedSignatureIndex,
  stripeVerification,
  selectedBonzahProduct,
  axleInsurance,
  bonzahCertificate,
  selectedCar,
  startDate,
  endDate,
}) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading1}>107 CAR RENTALS RENTAL AGREEMENT</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            This Car Rental Agreement is entered into between 107 Car Rentals
            LLC and {stripeVerification?.person_details.first_name}{" "}
            {stripeVerification?.person_details.last_name} (collectively the
            “Parties”) and outlines the respective rights and obligations of the
            Parties relating to the rental of a car. You also agree that you are
            not our agent for any purpose; and that you cannot assign delegate
            or transfer your obligations under the Rental Agreement and any
            discrete part thereof.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            1. IDENTIFICATION OF THE RENTAL VEHICLE
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Owner hereby agrees to rent to Renter a passenger vehicle identified
            as follows:
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Make: {selectedCar.vehicle.make}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Model:{selectedCar.vehicle.model}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Year:{selectedCar.vehicle.year}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            VIN:{selectedCar.vehicle.vin}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            License Plate: {selectedCar.vehicle.plate}
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            (Hereinafter referred to as “Rental Vehicle”).
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            2. RENTAL TERM
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            The term of this Car Rental Agreement runs from the date and hour of
            vehicle pickup as indicated just above the signature line at the
            bottom of this agreement until the return of the vehicle to Owner,
            and completion of all terms of this agreement by both Parties. The
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            *Estimated start date/time: {formatDateTime(startDate)}
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            *Estimated end date/time: {formatDateTime(endDate)}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            The Parties may shorten or extend the estimated term of rental by
            written mutual consent.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            3. MILEAGE
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Mileage use allotted is {selectedCar.miles_included} MILES PER DAY
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Rate for extra mileage: ${(selectedCar.miles_extra ?? 0) / 100} PER
            EXTRA MILE
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            4. RENTAL FEES
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Renter will pay to Owner rental fees for use of the Rental Vehicle
            as follows:
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            *Base fee: ${selectedCar.pricing.amount / 100} per [ Day (s) ]
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Rental fee for days beyond the rental term:
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            a) You will also pay a reasonable fee for cleaning the car’s
            interior upon return if any stains, dirt, odor, or soiling
            attributable to your use cannot be cleaned with our standard
            post-rental procedures as determined by us in our sole discretion.
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            b) If the key(s) or key fob(s) are not returned with the car, you
            may be charged additional fees ($250).
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            c) We maintain a non-smoking fleet, including a prohibition on the
            use of e-cigarettes in the car. You will pay an additional charge if
            you return the car and it smells or is soiled from smoke or
            e-cigarette vapor ($150).
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            d) You and any third party to whom any rental charges are billed,
            such as an insurer or employer, are jointly and severally
            responsible for payment of all such charges. If you direct us to
            bill any such charges to a third party, you represent that you are
            authorized to do so on behalf of the third party.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            f) If you use a car with automatic toll payment capability you will
            be charged the toll fee and additional accounting fee ($20).
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            g) 107 Car Rentals LLC makes every effort to ensure that all prices
            and descriptions quoted are correct and accurate. However, in the
            case of a manifest error or omission, 107 Car Rentals LLC reserves
            the right to rescind the Rental Agreement, even if we have already
            accepted your reservation and/or received your payment. Our
            liability in that event will be limited to the return of any money
            that you have paid with respect to the reservation. In the case of a
            manifest error in which we permit you to keep your reservation, we
            reserve the right to require that you pay the difference between the
            quoted price and the correct price, as confirmed in writing by 107
            Car Rentals LLC after the manifest error has been discovered. A
            "manifest error", as the term is used in this paragraph, means, in
            relation to an incorrect price, a price quoted in error by 107 Car
            Rentals LLC which is more than 15% less than the price that would
            have been quoted had the mistake not been made.
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            5. TAXES, SURCHARGES & FEES
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            You will also pay all applicable taxes (11%) as well as any
            additional charges provided on the Rental Agreement (3% Credit Card
            Fee)which are over and above the base rental rate. These may be
            surcharges and/or recovery fees to recover certain costs.
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            6. FINES, EXPENSES, COSTS AND ADMINISTRATIVE FEES
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            You will pay or reimburse us for all fines, penalties, interest, and
            court costs for parking, traffic, toll, and other violations,
            including storage liens and charges incurred because of your rental.
            You will also pay a reasonable administrative fee with respect to
            any violation of the Rental Agreement, such as for repossessing or
            recovering the car for any reason. You agree we may, in our sole
            discretion, pay all tickets, citations, fines, penalties and
            interest on your behalf directly to the appropriate authority and
            you will pay us for what we paid to the appropriate authority or
            their designated agents plus a reasonable administrative fee and any
            attorneys' fees and expenses we incur. You agree and acknowledge
            that we cooperate with all federal, state/provincial, municipal, and
            local officials charged with enforcing these infractions to provide
            any information necessary as they may request or may otherwise be
            required.
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            7. CHANGES
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Any change in the Rental Agreement or our rights must be in writing
            and signed by an authorized officer. You further agree that we have
            the unilateral right to change these Terms and Conditions from time
            to time either upon written notice to you, in paper or electronic
            form of communication provided. Such changes will apply to rentals
            that you reserve after such notice has been given, as indicated by
            the date of such notice, if sent in written form, or the date such
            changes are send through electronic means provided by you.
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            8. WHO MAY DRIVE THE CAR?
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            You represent to 107 Car Rentals LLC that you are a capable and
            validly licensed driver and will remain a capable and validly
            licensed driver throughout the term of your rental. You agree that
            we have the right to verify that your license has been validly
            issued and is in good standing (not suspended, revoked, or otherwise
            restricted in any way) as a condition precedent to each rental; and
            that we may in our sole discretion refuse to rent to you if your
            license is not in good standing. We reserve the right to deny
            rentals based upon (i) information about your license status, (ii)
            authenticity of your driver's license or other credentials, (iii)
            the inability to verify your identity or payment methods, (iv) your
            driving record provided by the Motor Vehicle Department of the
            jurisdiction that issued your license, or (v) any other information
            received from any other source in the business of validating an
            identity or the driver's license credential that we believe to be
            reliable. We reserve the right to validate your driving credentials
            and license good standing periodically without notice to you except
            as required by law. Except where otherwise specifically authorized
            by applicable law, only you, your spouse or domestic partner, or, if
            you rent from us under your employer’s corporate account agreement,
            your employer or a regular fellow employee incidental to business
            duties may drive the car (each a "Permitted Driver"), but only with
            your prior permission. Any Permitted Driver must be at least 21
            years old and must also be a capable and validly licensed driver at
            all times during which such person is operating the car. Any person
            other than you or a Permitted Driver that operates the car must sign
            an additional driver form at the time of the rental. We may charge
            for each additional driver authorized to drive the car, which will
            be specified on the Rental Contract. You acknowledge that you will
            remain financially responsible under the Rental Agreement at all
            times even if the car is operated by a Permitted Driver or someone
            other than yourself.
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            9. RETURN OF THE CAR
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            You agree to return the car to us in the same condition you received
            it, ordinary wear and tear excepted, on the date, at the time and to
            the location specified 7 RAVE STREET, HICKSVILLE, N.Y. 11801. You
            must return it sooner on our demand. If you return it earlier or
            later, a different or higher rental rate may apply and, if returned
            later, you may also be charged a late return fee. If you wish to
            extend any rental you must contact us at 516-703-9888 or use a
            method we approve to request the extension before your return date.
            We may or may not grant an extension or decline to grant it for the
            entire period you request, in our sole discretion
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            10. REPOSSESSING THE CAR
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            We can repossess the car at any time in our sole discretion for
            reasons that include but are not limited to the following: Rental is
            not returned as at when due, the car is found illegally parked,
            being used to violate the law or the terms of the Rental Agreement
            or appears to be abandoned. You agree that we need not notify you in
            advance and that we may take any actions reasonably necessary to
            obtain possession of the car. If the car is repossessed, you agree
            to pay or reimburse us for the actual and reasonable costs incurred
            by us to repossess the car. You agree that such costs will be
            charged to the credit or debit card or account you used to rent the
            car. You will also forfeit your security deposit if car is
            repossessed.
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            11. DAMAGE TO/LOSS OF THE CAR
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            If the car is lost or damaged you are responsible and you will pay
            us for all loss of or damage to the car regardless of cause, or who,
            or what caused it. If the car is damaged, you will pay our estimated
            repair cost, or if, in our sole discretion, we determine to sell the
            car in its damaged condition, you will pay the difference between
            the car’s retail fair market value before it was damaged and the
            sale proceeds. Where permitted by law, you authorize us to charge
            you for the actual cost of repair or replacement of lost or damaged
            items such as glass, mirrors, tires, and antenna, as part of your
            rental charges at the time of return. If the car is stolen and not
            recovered, you will pay us the car’s fair market value before it was
            stolen. As part of our loss, you will also pay for loss of use of
            the car, without regard to our fleet utilization, plus an
            administrative fee, plus towing and storage charges, if any
            (“Incidental Loss”). If your responsibility is covered by any
            insurance, credit card benefit, travel insurance or such other
            insurance or benefits, you authorize us to contact the benefit
            provider directly on your behalf and you assign all of your benefits
            directly to us to recover all consequential and incidental damages,
            including but not limited to the repairs of the car plus diminished
            value or the fair market retail value of the car (less salvage value
            plus costs incurred in the salvage-sale), and all Incidental Loss
            and administrative fees. If we collect our loss from a third party
            after we have collected our loss from you, we will refund the
            difference, if any, between what you paid us and what we collected
            from the third party. If the law of a jurisdiction covering this
            rental requires conditions on LDW that are different than the terms
            of the Rental Agreement, such as if your liability for ordinary
            negligence is limited by such law, that law prevails. You understand
            that you are not authorized to repair or have the car repaired
            without our express prior written consent. If you repair or have the
            car repaired without our consent, you will pay the estimated cost to
            restore the car to the condition it was in prior to your rental. If
            we authorize you to have the car repaired and the cost of repair is
            our responsibility, we will reimburse you for those repairs only if
            you give us the repair receipt.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            12. PROHIBITED USE OF THE CAR
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Certain uses of the car and other actions you or a driver may take,
            or fail to take, will violate the Rental Agreement. A VIOLATION OF
            THIS PARAGRAPH, WILL AUTOMATICALLY TERMINATE YOUR RENTAL AND IS AN
            EXCLUSION TO AND VOIDS ALL LIABILITY PROTECTION AND ANY OPTIONAL
            SERVICES THAT YOU HAVE ACCEPTED, INCLUDING BUT NOT LIMITED TO
            SUPPLEMENTAL LIABILITY INSURANCE, PERSONAL ACCIDENT INSURANCE,
            PERSONAL EFFECTS INSURANCE, ANY ROADSIDE ASSISTANCE PLAN, EMERGENCY
            SICKNESS PROTECTION AND LOSS DAMAGE WAIVER (LDW) OR PARTIAL DAMAGE
            WAIVER. IT ALSO MAKES YOU LIABLE TO US FOR ALL PENALTIES, FINES,
            FORFEITURES, LIENS AND RECOVERY AND STORAGE COSTS, INCLUDING ALL
            RELATED ATTORNEYS' FEES, LEGAL EXPENSES, FEES AND COSTS THAT WE MAY
            INCUR. It is a violation of this Paragraph if any of the following
            occurs:
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            A. You use or permit the car to be used: 1) by anyone other than an
            authorized driver, as defined in paragraph 5; 2) to carry passengers
            or property for hire or more passengers than the car has seat belts
            to carry; 3) to tow or push anything; 4) to be operated in a test,
            race or contest or on unpaved roads; 5) while the driver is under
            the influence of alcohol, any controlled substance, including
            without limitation, any federally controlled substance listed under
            the Controlled Substance Act, Title 21 of the United States Code (a
            "Controlled Substance"), or medications that affect vehicle
            operation and/or constitute driving while impaired under applicable
            law; 6) for conduct that could be charged as a crime such as a
            felony or misdemeanor, including the transportation of a Controlled
            Substance or contraband, stolen goods, illegal devices, or persons
            protected by prohibitions against human trafficking; 7) recklessly
            or while overloaded; or 8) if the car is driven into Mexico without
            our expressed permission.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            B. You or an additional driver, whether authorized or not: 1) fail
            to promptly report to 107 Car Rentals LLC any damage to or loss of
            the car when it occurs or when you learn of it and provide us with a
            written accident/incident report or fail to cooperate with our
            investigation; 2) Where required by law, failed to report an
            accident to law enforcement; 3) obtained the car through fraud or
            misrepresentation; 4) leave the car and fail to remove the keys (or
            key fobs) or close and lock all doors, close all windows and the
            trunk and the car is stolen or vandalized; or 5) intentionally or
            with willful disregard cause or allow damage to the car. C. You or
            an additional driver, whether authorized or not return the car after
            hours and the car is damaged, stolen or vandalized or you otherwise
            fail to take reasonable steps to secure the car, its keys, key fobs,
            or other remote entry and starting devices. D. Driving or operating
            this car while using a hand-held wireless communication device or
            other device that can receive or transmitting telephonic
            communications, electronic data, mail, or text messages shall be
            deemed a breach of the Rental Agreement.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            13. FUEL SERVICE CHARGE
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Most rentals come with a full tank of fuel, but that is not always
            the case.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            1) You may avoid a fuel service charge if you return the car with
            the fuel tank as full as when you received it and, if requested by
            us, present a receipt for your fuel purchase. If you put fuel into
            the car, you must use the correct fuel. If unsure call company
            officer and ask.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            14. SECURITY DEPOSIT
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Renter will be required to provide a security deposit to Owner in
            the amount of $300 (“Security Deposit”) to be used in the event of
            loss or damage to the Rental Vehicle during the term of this
            agreement. Owner may, in lieu of collection of a security deposit,
            place a hold on a credit card in the same amount. In the event of
            damage to the Rental Vehicle, Owner will apply the ENTIRE Security
            Deposit to defray the costs of necessary repairs or replacement. If
            the cost for repair or replacement of damage to the Rental Vehicle
            exceeds the amount of the Security Deposit, Renter will be
            responsible for payment to the Owner of the balance of this cost.
            Any late returns, physical or mechanical damage whatsoever to the
            vehicle or smoking in the vehicle will result in complete forfeiture
            of the deposit. NO EXCEPTIONS.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            16. INSURANCE
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            The Renter must provide to Owner with proof of insurance that would
            cover damage to the Rental Vehicle at the time this Car Rental
            Agreement is signed, as well as personal injury to the Renter,
            passengers in the Rented Vehicle, and other persons or property. If
            the Rental Vehicle is damaged or destroyed while it is in the
            possession of Renter, Renter agrees to pay any required insurance
            deductible and assign all rights to collect insurance proceeds to
            Owner.
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            17. INDEMNIFICATION
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Renter agrees to indemnify, defend, and hold harmless the Owner for
            any loss, damage, or legal actions against the Owner because of
            Renter’s operation or use of the Rented Vehicle during the term of
            this Car Rental Agreement. This includes any attorney fees
            necessarily incurred for these purposes. Renter will also pay for
            any parking tickets, moving violations, or other citations received
            while in possession of the Rented Vehicle.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            18. COLLECTIONS
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            If you do not pay all amounts due to us under the Rental Agreement
            upon demand, including all charges, fees, expenses, fines,
            penalties, and all matters associated with the rental of the car
            including, without limitation, payment for loss of or damage to the
            car, rental charges, parking, red light and traffic fines and
            penalties, toll charges, towing, storage and impoundment fees, we
            will take the following actions: a) You agree to pay a late charge
            of 1 & 1/2% per month on the past due balance or the highest rate
            permitted by applicable law, whichever is less (collectively,
            “Charges”). b) You agree to also pay for any costs that we incur in
            seeking to collect such Charges including, without limitation, court
            costs and attorney’s fees in addition to any administrative fees,
            cost of recovery, insufficient funds fees and collection fees
            (collectively, “Costs”). If the law permits, you authorize us and
            our collection agent, to contact you or your employer, at your place
            of business about the payment of any past due Charges or Costs.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            19. REPRESENTATIONS AND WARRANTIES
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Owner represents and warrants that to Owner’s knowledge, the Rental
            Vehicle is in good condition and is safe for ordinary operation of
            the vehicle.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Renter represents and warrants that Renter is legally entitled to
            operate a motor vehicle under the laws of this jurisdiction and will
            not operate it in violation of any laws, or in any negligent or
            illegal manner.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Renter has been given an opportunity to examine the Rental Vehicle
            in advance of taking possession of it, and upon such inspection, is
            not aware of any damage existing on the vehicle other than that
            notated by separate Existing Damage document.
          </Text>
        </View>

        <View>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            20. ENTIRE AGREEMENT
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            This Car Rental Agreement constitutes the entire agreement between
            the Parties with respect to this rental arrangement. No modification
            to this agreement can be made unless in writing signed by both
            Parties. Any notice required to be given to the other party will be
            made to the contact information below. There are no refunds for
            cancellations once vehicle is reserved or if vehicle breaks down
            during the course of the rental. 107 Car Rentals LLC will be happy
            to extend the rental period to accommodate lost time. However, in
            the event that the damage was due to negligence on the part of the
            renter, 107 Car Rentals LLC reserves the right to cancel the
            remainder of the rental without issuing a refund. However, if the
            mechanical breakdown was due to a lack of maintenance on the part of
            Executive Whips Houston, reasonable care will be taken to extend the
            rental period or provide a substitute vehicle.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.heading2, marginLeft: "25px" }}>
            21. GPS/Tracker-
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            Our Vehicles are equipped with GPS trackers to monitor the vehicle
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.heading3}>
            Renter’s name: {stripeVerification?.person_details.first_name}{" "}
            {stripeVerification?.person_details.last_name}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Phone # : {stripeVerification?.person_details.document_number}{" "}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Issued Date: {stripeVerification?.person_details.issued_date?.month}
            /{stripeVerification?.person_details.issued_date?.day}/
            {stripeVerification?.person_details.issued_date?.year}
          </Text>
        </View>
        {/* <View style={styles.section}>
          <Text style={styles.heading3}>
            Date : {stripeVerification?.person_details.expiration_date.day}/
            {stripeVerification?.person_details.expiration_date.month}/
            {stripeVerification?.person_details.expiration_date.year}
          </Text>
        </View> */}
        {selectedBonzahProduct.length != 0 ? (
          selectedBonzahProduct?.map((selectedBonzahOption) => (
            <View style={styles.section}>
              <Text style={styles.heading3}>
                Insurance Name : {selectedBonzahOption.name}
              </Text>
            </View>
          ))
        ) : (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance Policy # : {axleInsurance?.results["policyNumber"]}
            </Text>
          </View>
        )}
        {selectedBonzahProduct.length != 0 ? (
          <View style={styles.section}>
            <Text style={styles.heading3}>Insurance Provided by : Bonzah</Text>
          </View>
        ) : (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance Company : {axleInsurance?.results["carrier"]}
            </Text>
          </View>
        )}
        {selectedBonzahProduct.length != 0 && (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance agreement :{" "}
              {bonzahCertificate !== null
                ? bonzahCertificate
                : "Will be provided in the email"}
            </Text>
          </View>
        )}
        <View style={styles.section}>
          <Text style={styles.heading1}>Renter’s signature</Text>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              ...styles.heading1,
              ...{ fontFamily: signatureStylesText[selectedSignatureIndex] },
            }}
          >
            {stripeVerification?.person_details.first_name}{" "}
            {stripeVerification?.person_details.last_name}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const DownloadLink: React.FC = () => {
  const {
    startDate,
    endDate,
    selectedBonzahProduct,
    stripeVerification,
    selectedSignatureIndex,
    axleInsurance,
    selectedCar,
  } = useApp();
  return (
    <div>
      <PDFDownloadLink
        document={
          <RentalAgreement
            selectedSignatureIndex={selectedSignatureIndex}
            stripeVerification={stripeVerification!}
            selectedBonzahProduct={selectedBonzahProduct}
            axleInsurance={axleInsurance}
            selectedCar={selectedCar!}
            startDate={startDate}
            endDate={endDate}
          />
        }
        fileName="rental_agreement.pdf"
        className=" cursor-pointer"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Rental Agreement"
        }
      </PDFDownloadLink>
    </div>
  );
};

export default RentalAgreement;
