import React, { FC, useEffect, useState } from "react";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export interface HeaderFilterProps {
  tabActive: string;
  tabs: string[];
  heading: ReactNode;
  subHeading?: ReactNode;
  onClickTab?: (item: string) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading = "🎈 Latest Articles",
  onClickTab,
}) => {
  const navigate = useNavigate();
  const [tabActiveState, setTabActiveState] = useState(tabActive);
  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (item: string) => {
    onClickTab && onClickTab(item);
    setTabActiveState(item);
  };

  return (
    <div className="flex flex-col mb-8 relative">
      <div className="flex items-center justify-between ">
        <Heading>{heading}</Heading>

        <span className="hidden sm:block flex-shrink-0 border border-neutral-200 rounded-xl ">
          <ButtonSecondary
            className="!leading-none bg-transparent "
            onClick={() => {
              navigate("/listing-car");
            }}
          >
            <span className="text-text-color font-semibold mx-2">View All</span>
            <svg
              width="23"
              height="16"
              viewBox="0 0 23 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.51587 7C0.963584 7 0.515869 7.44772 0.515869 8C0.515869 8.55228 0.963584 9 1.51587 9V7ZM22.3959 8.70711C22.7864 8.31658 22.7864 7.68342 22.3959 7.29289L16.0319 0.928932C15.6414 0.538408 15.0082 0.538408 14.6177 0.928932C14.2272 1.31946 14.2272 1.95262 14.6177 2.34315L20.2746 8L14.6177 13.6569C14.2272 14.0474 14.2272 14.6805 14.6177 15.0711C15.0082 15.4616 15.6414 15.4616 16.0319 15.0711L22.3959 8.70711ZM1.51587 9H21.6888V7H1.51587V9Z"
                fill="#002A62"
              />
            </svg>
          </ButtonSecondary>
        </span>
      </div>
    </div>
  );
};

export default HeaderFilter;
